<template>
    <div :class="{ isUser: !isAdmin }" class="order-detail" style="overflow-x: auto; padding: 5px">
        <md-card style="width: 1000px; margin: 0 auto" v-if="order">
            <div ref="invoice">
                <md-card-header>
                    <div class="header">
                        <div class="left-container">
                            <div class="md-title">
                                <span style="display: inline-block; min-width: 260px"> Booking</span>

                                <span v-if="order.pickup" style="font-size: 20px">(Customer Pickup)</span>
                            </div>
                            <md-input-container v-if="order.staff_id">
                                <label for>Staff Assign</label>
                                <span class="val" style="font-size: 17px; line-height: 30px">{{ order.staff_name }}</span>
                                <md-input :value="order.staff_id" type="hidden"></md-input>
                            </md-input-container>
                            <md-input-container>
                                <label for>Status</label>
                                <md-select :disabled="!isAdmin" @change="confirmStatusChange" v-model="order.status">
                                    <md-option :key="ix" :value="o" v-for="(o, ix) in orderStatuses">{{ o }}</md-option>
                                </md-select>
                            </md-input-container>
                            <md-input-container v-if="false">
                                <label for>Payment status</label>
                                <md-select :disabled="!isAdmin" @change="save" v-model="order.paymentStatus">
                                    <md-option :key="ix" :value="o" v-for="(o, ix) in paymentStatuses">{{ o }}</md-option>
                                </md-select>
                            </md-input-container>
                        </div>

                        <div class="right-container">
                            <div class="md-subtitle">
                                <!-- <p>{{order.vendor_name}}</p> -->
                                <!-- <p>{{order.vendor_email}}</p> -->
                                <template v-if="vendor">
                                    <p>{{ vendor.business }}</p>
                                    <p>{{ vendor.address }}</p>
                                    <p>{{ vendor.city }},{{ vendor.state }},{{ vendor.zip }}</p>
                                    <p>{{ vendor.phone }}</p>
                                    <p>{{ vendor.email }}</p>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="header">
                        <div class="left-container">
                            <h3>{{ order.user_name | titlecase }}</h3>
                            <p>{{ order.user_company }}</p>
                            <p>{{ order.user_email }}</p>
                            <p>{{ order.address.phone }}</p>
                            <p>{{ order.address.address }}</p>
                            <p>{{ order.address.apartNo }}</p>
                            <p>{{ order.address.city }}, {{ states[order.address.state] || order.address.state }}, {{ order.address.zip }}</p>
                            <p>{{ order.address.country }}</p>
                        </div>
                        <div class="right-container order-info" v-if="!(user.role == 'staff' && !hasPermission('view_order_amount'))">
                            <p>
                                <label for>Booking ID:</label>
                                <span>{{ order.orderNo }}</span>
                            </p>
                            <p>
                                <label for>Created:</label>
                                <span>{{ order.createdAt | date("MMM DD YYYY") }}</span>
                            </p>
                            <P>
                                <label for>Booking:</label>
                                <span>{{ order.amount.total | currency }}</span>
                            </P>
                            <p>
                                <label for>Delivery:</label>
                                <span>{{ order.amount.shipping | currency }} </span>
                            </p>
                            <p v-if="order.amount.offhour">
                                <label for>Off-Hour Service Fee:</label>
                                <span>{{ order.amount.offhour | currency }} </span>
                            </p>
                            <p>
                                <label for>Security Deposit:</label>
                                <span>{{ order.amount.securityDeposit || 0 | currency }}</span>
                            </p>
                            <p>
                                <label for>Total:</label>
                                <span>{{ (order.amount.total + order.amount.shipping + order.amount.offhour + order.amount.securityDeposit) | currency }}</span>
                            </p>
                            <p>
                                <label for>Deposit:</label>
                                <span>{{ order.paid || 0 | currency }}</span>
                            </p>
                            <p v-if="order.discount">
                                <label for>Discount:</label>
                                <span>{{ order.discount || 0 | currency }}</span>
                            </p>
                            <p>
                                <label for>Balance:</label>
                                <a>
                                    <span v-if="order.status != 'Cancelled'">{{
                                        (order.amount.total + order.amount.shipping + order.amount.offhour + order.amount.securityDeposit - (order.paid || 0) - (order.discount || 0)) | currency
                                    }}</span>
                                    <span v-if="order.status == 'Cancelled'">{{ 0 | currency }}</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </md-card-header>
                <md-card-content>
                    <md-table>
                        <md-table-header>
                            <md-table-row>
                                <md-table-head>
                                    <span>Item</span>
                                </md-table-head>
                                <md-table-head>
                                    <span>Rate</span>
                                </md-table-head>
                                <md-table-head>
                                    <span>Amount</span>
                                </md-table-head>
                                <md-table-head>
                                    <span>Deposit</span>
                                </md-table-head>
                            </md-table-row>
                        </md-table-header>
                        <md-table-body>
                            <md-table-row :key="ix" v-for="(item, ix) in order.items">
                                <md-table-cell>
                                    <div>
                                        <router-link :to="'/products/' + item.product._id">
                                            <span>{{ item.product.name }}</span>
                                            <span>/ {{ item.variant.name }}</span>
                                        </router-link>
                                        <br />
                                        <span :key="addOn._id" v-for="addOn in item.addOnItems">
                                            <span v-if="addOn.quantity > 0">
                                                {{ addOn.name }} {{ addOn.quantity }}{{ addOn.unit }} * {{ addOn.price | currency }}
                                                <br />
                                            </span>
                                        </span>
                                        <span v-if="item.product.rental">{{ item.rentalStartDate | date }} - {{ item.rentalEndDate | date }}</span>
                                        <span v-if="!item.product.rental">{{ item.rentalStartDate | date }} - {{ getDelayDate(item.rentalStartDate) | date }}</span>
                                    </div>
                                </md-table-cell>
                                <md-table-cell>
                                    <template v-if="!(user.role == 'staff' && !hasPermission('view_order_amount'))">
                                        <span v-if="item.product.rental"
                                            >{{ item.quantity }} @ {{ item.variant.price | currency }} * {{ item.rentalPeriod }}{{ item.product.rentalInterval | intervalName }} +
                                            {{ (item.subTotal / item.quantity - item.variant.price * item.rentalPeriod) | currency }}</span
                                        >
                                        <span v-if="!item.product.rental"
                                            >{{ item.quantity }} @ {{ item.variant.price | currency }} + {{ (item.subTotal / item.quantity - item.variant.price) | currency }}</span
                                        >
                                    </template>
                                    <template v-else>
                                        <span>{{ item.quantity }} </span>
                                    </template>
                                </md-table-cell>
                                <md-table-cell>
                                    <span v-if="!(user.role == 'staff' && !hasPermission('view_order_amount'))">{{ item.subTotal | currency }}</span>
                                </md-table-cell>
                                <md-table-cell>
                                    <span v-if="!(user.role == 'staff' && !hasPermission('view_order_amount'))">{{ item.subPaid | currency }}</span>
                                </md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>
                                    <span>Total:</span>
                                </md-table-cell>
                                <md-table-cell>
                                    <span></span>
                                </md-table-cell>
                                <md-table-cell>
                                    <span v-if="!(user.role == 'staff' && !hasPermission('view_order_amount'))">{{ order.amount.total | currency }}</span>
                                </md-table-cell>
                                <md-table-cell>
                                    <span v-if="!(user.role == 'staff' && !hasPermission('view_order_amount'))">{{ subPaidTotal | currency }}</span>
                                </md-table-cell>
                            </md-table-row>
                        </md-table-body>
                    </md-table>
                    <div v-if="order.note">
                        Notes:
                        <pre style="margin: 0"
                            >{{ order.note }}
                        </pre>
                    </div>
                    <div v-if="order.comment && isAdmin">
                        Comments:
                        <pre style="margin: 0"
                            >{{ order.comment }}
                        </pre>
                    </div>
                </md-card-content>
            </div>
            <md-card-actions v-if="!/Cancelled/.test(order.status) && isUser">
                <md-button @click="openQAQuestionModal" class="md-primary">
                    Open New Ticket
                    <md-icon>question_answer</md-icon>
                </md-button>
                <md-button @click="openPay" class="md-accent" v-if="hasRental && !order.paid">
                    Pay the depoist
                    <md-icon>payment</md-icon>
                </md-button>
                <md-button @click="openPay" class="md-accent" v-if="!hasRental && !order.paid">
                    Pay the order
                    <md-icon>payment</md-icon>
                </md-button>
            </md-card-actions>
            <pay-confirm :amount="payAmount" :email="order.user_email" @confirm="pay" ref="payConfirm" v-if="isUser"></pay-confirm>
        </md-card>
        <md-card style="width: 1000px; margin: 0 auto">
            <md-card-actions style="justify-content: flex-start">
                <md-menu md-direction="bottom left" md-size="4">
                    <md-button @click="generatePDF" class="md-icon-button" title="Download" v-if="!(user.role == 'staff' && !hasPermission('view_order_amount'))">
                        <md-icon>file_download</md-icon>
                    </md-button>
                    <md-button class="md-icon-button" md-menu-trigger v-show="isAdmin">
                        <md-icon>near_me</md-icon>
                    </md-button>
                    <md-menu-content>
                        <md-menu-item @click="handleFindOnMap">
                            <span>Find on map</span>
                            <md-icon>near_me</md-icon>
                        </md-menu-item>
                        <md-menu-item @selected="call">
                            <span>Call</span>
                            <md-icon>phone</md-icon>
                        </md-menu-item>
                    </md-menu-content>
                </md-menu>
            </md-card-actions>
        </md-card>
        <confirm @cancel="getOrder" @ok="save" ref="status-confirm"></confirm>
        <q-a-question-modal :orderId="id" ref="qaQuestionModal"></q-a-question-modal>
    </div>
</template>
<script>
import { states, orderStatuses, paymentStatuses } from "@/config";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import PayConfirm from "@/components/PayConfirm";
import Confirm from "@/components/modal/Confirm";
import QAQuestionModal from "@/components/QA/QAQuestionModal.vue";
import jsPDF from "jspdf";
import UserAutocomplete from "@/components/UserAutocomplete.vue";

export default {
    props: ["id", "mode"], //role: pdf user admin
    components: {
        QAQuestionModal,
        PayConfirm,
        Confirm,
        UserAutocomplete
    },
    data() {
        return {
            ready: false,
            isGenerating: false,
            vendor: null,
            order: null,
            pays: [],
            loactionInfo: ""
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole",
            hasPermission: "user/hasPermission"
        }),
        isUser() {
            return this.mode === "my";
        },
        isAdmin() {
            return this.mode === "admin";
        },
        hasRental() {
            const items = this.order ? this.order.items : [];
            return items.find(i => i.product.rental);
        },
        subPaidTotal() {
            var items = this.order ? this.order.items : [];
            return items.reduce((acc, item) => acc + item.subPaid, 0);
        },
        paymentStatuses() {
            return paymentStatuses;
        },
        orderStatuses() {
            if (!this.pays.length) {
                return orderStatuses;
            }
            return orderStatuses.filter(o => o != "Void");
        },
        states() {
            return states;
        },
        hasRemaing() {
            const { order } = this;
            return order.amount.total + order.amount.shipping + order.amount.offhour + order.amount.securityDeposit > (order.paid || 0);
        },
        payAmount() {
            let amount = this.order.amount.total + this.order.amount.shipping + this.order.amount.offhour + this.order.amount.securityDeposit;
            if (this.hasRental) {
                amount = this.order.amount.deposit + this.order.amount.shipping + this.order.amount.offhour + this.order.amount.securityDeposit;
            }
            return amount;
        },
        googleMapUrl() {
            const { address, country, city, state, zip } = this.order.address;
            const targetAddress = `${address},${city},${states[state] || state},${zip || ""},${country}`;
            return `https://www.google.com/maps/dir/${this.loactionInfo || ""}/${targetAddress}`;
        },
        getDelayDate() {
            return date => moment(date).add(2, "hour");
        }
    },
    methods: {
        async getOrder() {
            const api = this.mode === "pdf" ? `orders/pdf/${this.id}?sign=${this.$route.query.sign}` : `orders/${this.id}`;
            var result = await this.$store.dispatch(`crud/get`, {
                api
            });
            this.order = result;
            this.order.amount = this.order.amount || {};
            this.order.address = this.order.address || {};

            //prevent md-select trigger onchange event when init
            setTimeout(() => {
                this.ready = true;
            }, 0);
        },
        async getVendor(id) {
            var result = await this.$store.dispatch("crud/get", {
                api: `users/vendor/${id}`
            });
            this.vendor = result.vendor || null;
        },
        async getPays() {
            let result = await this.$store.dispatch("crud/get", {
                api: `pays`,
                params: {
                    orderId: this.id
                }
            });
            this.pays = result.data;
        },
        async save() {
            if (!this.ready || !this.isAdmin) {
                return;
            }
            let result = await this.$store.dispatch("crud/put", {
                api: `orders/${this.id}`,
                data: this.order
            });
            this.$store.commit("setMessage", "Updated Successfully");
            if (this.order && this.order.status == "Void") {
                this.$router.push("/admin/orders/manage");
            } else {
                this.getOrder();
            }
        },
        confirmStatusChange(status) {
            if (!this.ready || !this.isAdmin) {
                return;
            }
            let content = `Are you sure to change the order status to ${status} ?`;
            if (status == "Void") {
                content = `Are you sure to change the order status to ${status} ? It will delete this booking.`;
            }
            this.$refs["status-confirm"].open("Status Change", content);
        },
        call() {
            location.href = `tel:${this.order.address.phone}`;
        },
        openPay() {
            this.$refs["payConfirm"].open();
        },
        handleFindOnMap() {
            window.open(this.googleMapUrl);
        },
        getGeolocation() {
            return new Promise(res => {
                setTimeout(() => res(""), 5000);
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function(position) {
                            const {
                                coords: { latitude, longitude }
                            } = position;
                            res(`${latitude},${longitude}`);
                        },
                        function() {
                            res("");
                        }
                    );
                } else {
                    res("");
                }
            });
        },
        async pay({ card }) {
            let result = await this.$store.dispatch("crud/post", {
                api: `pays/stripe/pay/${this.order._id}`,
                data: {
                    card
                }
            });
            this.$store.commit("setMessage", "Payment Success", { root: true });
            console.info(result);
            this.getOrder();
        },
        escape2Html(str) {
            const arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
            return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
                return arrEntities[t];
            });
        },
        async generatePDF() {
            const api = `orders/pdf/${this.id}`;
            await this.$store.dispatch(`crud/download`, {
                api,
                filename: `${this.id}.pdf`
            });
        },
        async generatePDFByFrontend() {
            // remove
            if (this.isGenerating) return;
            this.isGenerating = true;
            const { order, vendor } = this;
            let canViewAmount = !(this.user.role == "staff" && !this.hasPermission("view_order_amount"));
            // canViewAmount = false;

            // width: 210 height: 297
            // padding 12.7
            let offsetTop = 12.7,
                offsetLeft = 12.7,
                offsetRight = 210 - offsetLeft,
                offsetBottom = 297 - offsetTop,
                offsetWidth = 210 - 12.7 * 2,
                sizes = [9, 12, 18],
                font = ["Helvetica", ""],
                verticalOffset = offsetTop,
                colors = [
                    [187, 187, 187],
                    [158, 158, 158],
                    [51, 51, 51],
                    [0, 0, 0]
                ];

            const doc = new jsPDF("p", "mm", "a4");
            doc.setFont(...font);

            // Title
            doc.setFontType("bold");
            this.renderText({
                doc,
                size: sizes[2],
                color: colors[3],
                offsetTop: verticalOffset,
                offsetLeft,
                text: order.pickup ? `Booking (Customer Pickup)` : "Booking"
            });
            doc.setFontType("normal");
            verticalOffset += sizes[2] / 2;

            // State
            this.renderText({
                doc,
                size: sizes[0],
                color: colors[1],
                offsetTop: verticalOffset,
                offsetLeft,
                text: "Status"
            });
            verticalOffset += sizes[1] / 2;

            this.renderText({
                doc,
                size: sizes[1],
                color: colors[2],
                offsetTop: verticalOffset,
                offsetLeft,
                text: this.order.status
            });
            verticalOffset += sizes[0] / 4;
            doc.setDrawColor(187, 187, 187);
            doc.line(offsetLeft, verticalOffset, offsetLeft + 40, verticalOffset);

            // Vendor
            verticalOffset = offsetTop;
            const vendorTexts = [vendor.business, vendor.address, vendor.city + vendor.state + vendor.zip, vendor.phone, vendor.email];
            console.log("vendorTexts", vendorTexts);
            verticalOffset =
                this.renderMutileLineText("vendor", {
                    doc,
                    verticalOffset,
                    size: sizes[1],
                    color: colors[2],
                    offsetRight,
                    offsetLeft,
                    texts: vendorTexts
                }).verticalOffset + 8;

            let balance = order.amount.total + order.amount.shipping + order.amount.offhour + order.amount.securityDeposit - (order.paid || 0) - (order.discount || 0);
            if (order.status == "Cancelled") {
                balance = 0;
            }

            // Order Info Right
            const infoRight = [
                order.orderNo,
                this.$options.filters.date(order.createdAt, "MMM DD YYYY"),
                this.$options.filters.currency(order.amount.total),
                this.$options.filters.currency(order.amount.shipping),
                this.$options.filters.currency(order.amount.offhour),
                this.$options.filters.currency(order.amount.securityDeposit || 0),
                this.$options.filters.currency(order.amount.total + order.amount.shipping + order.amount.offhour + order.amount.securityDeposit),
                this.$options.filters.currency(order.paid || 0),
                order.discount && this.$options.filters.currency(order.discount || 0),
                this.$options.filters.currency(balance)
            ].filter(Boolean);

            const infoRightData = this.renderMutileLineText("info-right", {
                doc,
                verticalOffset,
                size: sizes[1],
                color: colors[2],
                offsetRight: offsetRight - 1,
                offsetLeft,
                texts: infoRight
            });
            const infoRightOffsetLeft = offsetRight - infoRightData.maxTextWidth;
            const infoRightOffsetTop = verticalOffset - sizes[1] / 4;
            doc.setDrawColor(...colors[0]);
            doc.rect(infoRightOffsetLeft - 2, infoRightOffsetTop - 2, infoRightData.maxTextWidth + 4, infoRightData.verticalOffset - infoRightOffsetTop);

            // Order Info Left
            const infoLeft = [
                //
                "Booking ID:",
                "Created:",
                "Booking:",
                "Delivery:",
                "Off-Hour Service Fee:",
                "Security Deposit:",
                "Total:",
                "Deposit:",
                order.discount && "Discount:",
                "Balance:"
            ].filter(Boolean);
            const infoLeftData = this.renderMutileLineText("info-left", {
                doc,
                verticalOffset,
                size: sizes[1],
                color: colors[2],
                offsetRight: infoRightOffsetLeft - 4,
                offsetLeft,
                texts: infoLeft
            });
            if (canViewAmount) {
                doc.setFillColor(...colors[0]);
                doc.rect(infoRightOffsetLeft - infoLeftData.maxTextWidth - 6, infoRightOffsetTop - 2, infoLeftData.maxTextWidth + 4, infoRightData.verticalOffset - infoRightOffsetTop, "F");
                this.renderMutileLineText("info-left", {
                    doc,
                    verticalOffset,
                    size: sizes[1],
                    color: colors[2],
                    offsetRight: infoRightOffsetLeft - 4,
                    offsetLeft,
                    texts: infoLeft
                });
            }

            // Order Address
            const orderAddressTexts = [
                this.$options.filters.titlecase(order.user_name),
                order.user_email,
                order.address.phone,
                order.address.address,
                order.address.apartNo,
                `${order.address.city},${states[order.address.state] || order.address.state},${order.address.zip}`,
                order.address.country
            ];
            const addressData = this.renderMutileLineText("order", {
                doc,
                verticalOffset,
                size: sizes[1],
                color: colors[2],
                offsetRight,
                offsetLeft,
                texts: orderAddressTexts
            });

            verticalOffset = canViewAmount ? (addressData.verticalOffset > infoLeftData.verticalOffset ? addressData.verticalOffset : infoLeftData.verticalOffset) : addressData.verticalOffset;
            verticalOffset += 12;

            // Item list
            const itemListOffsetLeft = offsetLeft + 5;
            // TODO something error
            const itemListOffsetRight = 210 - 5;
            const itemListWidth = itemListOffsetRight - itemListOffsetLeft;

            const itemListPerWidth = itemListWidth / 14;
            const itemListItemOffsetLeft = [itemListOffsetLeft, itemListOffsetLeft + itemListPerWidth * 6, itemListOffsetLeft + itemListPerWidth * 10, itemListOffsetLeft + itemListPerWidth * 12];
            doc.setFontType("bold");
            (canViewAmount ? ["Item", "Rate", "Amount", "Deposit"] : ["Item"]).forEach((text, index) => {
                this.renderText({
                    doc,
                    size: sizes[0],
                    color: colors[1],
                    offsetTop: verticalOffset,
                    offsetLeft: itemListItemOffsetLeft[index],
                    text: text
                });
            });
            verticalOffset += sizes[0] / 2;
            doc.setFontType("normal");

            let itemTexts = order.items.map(item => {
                const { addOnItems = [] } = item;
                const itemInfo = [item.product.name + "/" + (item.variant.name || "")];
                const date = item.product.rental
                    ? `${this.$options.filters.date(item.rentalStartDate)} - ${this.$options.filters.date(item.rentalEndDate)} `
                    : `${this.$options.filters.date(item.rentalStartDate)} - ${this.$options.filters.date(this.getDelayDate(item.rentalStartDate))} `;
                itemInfo.push(date);
                addOnItems.forEach(addOnItem => {
                    const { name, price, quantity, unit } = addOnItem;
                    if (quantity > 0) {
                        itemInfo.push(`${name} ${quantity}${unit} * ${this.$options.filters.currency(price)}`);
                    }
                });

                let rate = "";
                if (item.product.rental) {
                    rate = `${item.quantity} @ ${this.$options.filters.currency(item.variant.price)} * ${item.rentalPeriod}${this.$options.filters.intervalName(
                        item.product.rentalInterval
                    )} + ${this.$options.filters.currency(item.subTotal / item.quantity - item.variant.price * item.rentalPeriod)}`;
                } else {
                    rate = `${item.quantity} @ ${this.$options.filters.currency(item.variant.price)} + ${this.$options.filters.currency(item.subTotal / item.quantity - item.variant.price)}`;
                }
                const amount = this.$options.filters.currency(item.subTotal);
                const deposit = this.$options.filters.currency(item.subPaid);
                return canViewAmount ? [itemInfo, rate, amount, deposit] : [itemInfo];
            });

            if (canViewAmount) {
                itemTexts.push(["Total:", null, this.$options.filters.currency(order.amount.total), this.$options.filters.currency(this.subPaidTotal)]);
            }

            itemTexts.forEach(texts => {
                const size = sizes[0];
                const color = colors[2];

                doc.setDrawColor(...colors[0]);
                doc.line(itemListOffsetLeft - 2, verticalOffset, itemListWidth + 4, verticalOffset);

                texts.forEach((text, index) => {
                    if (!text) return;
                    let offsetTop = verticalOffset + size / 4;
                    if (texts[1]) {
                        offsetTop += size / 4;
                    } else {
                        offsetTop += size / 2;
                    }
                    this.renderText({
                        doc,
                        size,
                        color,
                        offsetTop,
                        offsetLeft: itemListItemOffsetLeft[index],
                        text: text
                    });
                });
                verticalOffset += (size * 5) / 4;
                if (texts[0] && texts[0].length && texts[0].length > 2) {
                    verticalOffset += ((texts[0].length - 2) * size) / 2;
                }
            });
            verticalOffset += sizes[0];

            if (order.note) {
                const lines = doc.splitTextToSize(`Notes: ${order.note}`, offsetWidth);
                this.renderText({
                    doc,
                    size: sizes[1],
                    color: colors[2],
                    offsetTop: verticalOffset,
                    offsetLeft,
                    text: lines
                });
                verticalOffset += lines.length * (sizes[1] / 2);
            }

            // if (order.comment) {
            //     const lines = doc.splitTextToSize(`Comments: ${order.comment}`, offsetWidth);
            //     this.renderText({
            //         doc,
            //         size: sizes[1],
            //         color: colors[2],
            //         offsetTop: verticalOffset,
            //         offsetLeft,
            //         text: lines
            //     });
            //     verticalOffset += lines.length * (sizes[1] / 2);
            // }

            const { value } = await this.getTermsOfService();
            // let tosdiv = document.createElement("div");
            // tosdiv.innerHTML = value;
            // const TOSTexts = tosdiv.innerText.split("\n");

            const TOSTexts = this.escape2Html(value)

                .replace(/<p.*?>/gi, "")
                .split("</p>");

            const TOSSize = sizes[1];
            const TOSColor = colors[2];
            console.log(TOSTexts);
            TOSTexts.forEach(text => {
                if (/^<strong.*?>.*?<\/strong>$/.test(text) || /^<em.*?>.*?<\/em>$/.test(text)) {
                    doc.setFontType("bold");
                }

                text = text
                    .replace(/<br>/gi, "")
                    .replace(/<\/br>/gi, "")
                    .replace(/“/gi, '"')
                    .replace(/’/gi, "'")
                    .replace(/”/gi, '"')
                    .replace(/<span.*?>/gi, "")
                    .replace(/<\/span>/gi, "")
                    .replace(/<strong.*?>/gi, "")
                    .replace(/<\/strong>/gi, "")
                    .replace(/<em.*?>/gi, "")
                    .replace(/<\/em>/gi, "");

                const lines = doc.splitTextToSize(text, offsetWidth);

                lines.forEach(line => {
                    const afterInsertVerticalOffset = verticalOffset + TOSSize / 2;
                    if (afterInsertVerticalOffset > offsetBottom) {
                        doc.addPage();
                        verticalOffset = offsetTop;
                    }

                    this.renderText({
                        doc,
                        size: TOSSize,
                        color: TOSColor,
                        offsetTop: verticalOffset,
                        offsetLeft,
                        text: line
                    });
                    doc.setFontType("normal");

                    verticalOffset += TOSSize / 2;
                });
                verticalOffset += TOSSize / 4;
            });

            doc.save(`${this.order._id}.pdf`);
            this.isGenerating = false;
        },
        renderMutileLineText(type, { doc, texts, verticalOffset, size, color, offsetRight, offsetLeft }) {
            const lineWidth = 100;
            let maxLineLength = 0;
            let maxTextWidth = 0;

            const renderArr = texts.map((text, index) => {
                if (!text) {
                    text = "";
                }
                const lines = doc.splitTextToSize(text, lineWidth);
                const lineLength = lines.length;
                const renderData = {
                    doc,
                    size,
                    color,
                    offsetTop: verticalOffset,
                    text: lines
                };

                verticalOffset += lineLength * (size / 2);
                const textWidth = (doc.getStringUnitWidth(text) * size) / (72 / 25.6);
                if (type === "info-right") {
                    renderData.offsetLeft = offsetRight - textWidth;
                }
                if (textWidth > maxTextWidth) maxTextWidth = textWidth;
                if (lines.length > maxLineLength) maxLineLength = lines.length;

                return renderData;
            });
            let renderOffsetLeft;

            if (maxLineLength > 1) {
                renderOffsetLeft = offsetRight - lineWidth;
            } else {
                renderOffsetLeft = offsetRight - maxTextWidth;
            }

            if (type === "order") {
                renderOffsetLeft = offsetLeft;
            }

            renderArr.forEach((data, index) => {
                if (index === 0 && type === "order") {
                    doc.setFontType("bold");
                }
                if (type !== "info-right") {
                    data.offsetLeft = renderOffsetLeft;
                }
                this.renderText(data);
                doc.setFontType("normal");
            });

            return {
                maxTextWidth,
                verticalOffset
            };
        },
        renderText({ doc, size, color, offsetLeft, offsetTop, text }) {
            doc.setFontSize(size);
            if (color) {
                doc.setTextColor(...color);
            }
            if (!text) {
                text = "";
            }
            doc.text(offsetLeft, offsetTop, text);
        },
        getTermsOfService() {
            return this.$store.dispatch("crud/get", {
                api: "settings/terms-of-service"
            });
        },
        openQAQuestionModal() {
            this.$refs["qaQuestionModal"].open();
        }
    },
    async created() {
        this.getPays();
        await this.getOrder();
        await this.getVendor(this.order.vendor_id);
        this.loactionInfo = await this.getGeolocation();
    }
};
</script>
<style lang="less">
.order-detail {
    p,
    h3 {
        word-break: break-all;
    }

    .header {
        display: flex;
        width: 100%;
    }

    .header p {
        margin: 0;
    }

    .header .left-container {
        flex: 1;
    }

    .header .left-container .md-input-container {
        width: 200px;
    }

    .left-container p {
        margin: 0;
    }

    .left-container h3 {
        margin: 5px 0;
        padding: 0;
    }

    .order-info {
        border: 1px solid #bbb;
    }

    .order-info p {
        line-height: 23px;
        display: flex;
        justify-content: space-between;
        padding-right: 5px;
    }

    .order-info p label {
        background: #bbb;
        width: 150px;
        color: #333;
        padding-left: 5px;
        margin-right: 5px;
    }

    .md-card-content {
        margin: 0 20px;
    }
    .md-table-cell span {
        white-space: nowrap;
    }
    &.isUser {
        .md-select {
            &:after {
                display: none;
            }
        }

        .md-select-value {
            color: rgba(0, 0, 0, 0.87) !important;
        }
    }

    @media (max-width: 600px) {
        // & {
        //     background-color: red;
        // }

        .md-card {
            width: 100% !important;

            .header {
                flex-direction: column;

                .right-container {
                    margin-bottom: 20px;
                }

                .order-info {
                    margin-bottom: 0;
                    margin-top: 20px;
                }
            }
        }

        .md-card-content {
            margin: 0;
        }
    }
}
</style>
